<template>
  <!-- 会员中心 -->

  <div class="ybmxm">
    <div class="project">
      <div style="padding: 0 30px">
        <h3>收藏的资源资产</h3>
        <upLook v-if="UpLook" @closeDialog5="closeDialog5" ref="UpLook" />
        <payment
          :visible="dialogVisible"
          @close="dialogClose"
          :payInfo="payInfo"
        />
        <examineView
          v-if="examineVisible"
          :visible="examineVisible"
          @close="examineClose"
          @savePzxx="openPzxx"
          :data="examineInfo"
        ></examineView>
        <el-form
          class="query-form"
          :model="form"
          label-position="right"
          label-width="80px"
          inline
          ref="form"
        >
          <el-form-item label="地区">
            <el-cascader
              style="width: 140px"
              v-model="form.region"
              :props="props"
              :options="regionOptions"
              :show-all-levels="false"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="产权类别">
            <el-select style="width: 140px" v-model="form.type">
              <el-option
                v-for="item in typeOptions"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <!-- <el-form-item label="状态">
            <el-select style="width: 140px" v-model="form.status">
              <el-option
                v-for="item in shTypes"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item label="项目名称">
            <el-input
              style="width: 140px"
              v-model="form.name"
              placeholder="项目名称"
            >
              <!-- <i slot="suffix" class="el-input__icon">
                <img src="imgs/icon-search2.png" alt="">
              </i> -->
            </el-input>
          </el-form-item>
          <div class="button" style="margin-top: 0">
            <el-form-item>
              <el-button class="query-btn" type="primary" @click="query"
                >查询</el-button
              >
              <el-button class="query-btn" type="primary" @click="clear"
                >清空</el-button
              >
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="table-card">
        <div class="table-con">
          <el-table
            border
            class="table"
            :data="tableData"
            style="width: 100%"
            :row-class-name="handleRowClass"
          >
            <el-table-column
              align="center"
              prop="xmbh"
              label="项目编号"
              width="200px"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="xmmc"
              label="项目名称"
              width="200px"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="jyfs"
              label="交易方式"
              width="160px"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="zcmj"
              label="面积"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="lznx"
              label="年限"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="gpjg"
              label="挂牌价格"
            ></el-table-column>
            <el-table-column align="center" prop="jjzt" label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.jjzt == '0'">已发布</span>
                <span v-else-if="scope.row.jjzt == '1'">竞标中</span>
                <span v-else-if="scope.row.jjzt == '2'">竞标完成</span>
                <span v-else-if="scope.row.jjzt == '3'">流拍</span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              align="center"
              prop="pzscsj"
              label="审核时间"
            ></el-table-column> -->
            <el-table-column align="left" label="操作" width="200">
              <template slot-scope="scope">
                <el-button
                  style="height: 28px; padding: 0 8px"
                  type="primary"
                  @click="showDetail(scope.row)"
                  >查看</el-button
                >
                <el-button
                  style="height: 28px; padding: 0 8px"
                  type="primary"
                  @click="noSelect(scope.row)"
                  >取消收藏</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagenation">
          <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="total"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <bidding-dialog
      v-if="dialogVisiblejj"
      :dialogVisible="dialogVisiblejj"
      :data="current"
      @close="dialogClosejj"
      @submit="submit"
    ></bidding-dialog>
  </div>
</template>

<script>
import {
  getSignedUpXmxxByPg,
  paymentForProject,
  getRegionList,
  getAccount,
  savePzxx,
  showAccount,
  selectCollectionTrade,
  cancelCollectionXMXX
} from "@/api/projectManage";
import { getCertificateStatus } from "@/api/account";
import { getPropertyType, getBzjCheckResult } from "@/api/exchange";
import { uploadFile, deleteFile } from "@/api/fileApi";
import { getAreaTree } from "@/api/certificate"; 
import payment from "@/components/payment/newIndex.vue";
import examineView from "../components/examineView.vue";
import { mapGetters } from "vuex";
import { split, removeEmptyChildren } from "@/js/utils/util";
import biddingDialog from "../components/biddingDialog";
import { getXmxxDetail, saveBidding, getButtonStatus } from "@/api/exchange";

import {
  getEndProInfo,
  getMyBiddingResult,
  viewContract,
  checkContract,
} from "@/api/projectManage";
export default {
  components: {
    payment,
    examineView,
    biddingDialog,
  },
  data() {
    return {
      form: {
        area: "",
        region: "",
        status: "",
        type: "",
        changeType: "全部",
        code: "",
        name: "",
        maxArea: "",
      },
      tableData: [],
      regionOptions: [],
      props: {
        label: "areaName",
        children: "children",
        value: "id",
        fullPathName: "fullPathName",
        checkStrictly: true,
        emitPath: false,
      },
      areaOptions: [
        { label: "全部", value: "" },
        { label: "100亩以下", value: 0, value2: 100 },
        { label: "100亩-300亩", value: 100, value2: 300 },
        { label: "300亩-500亩", value: 300, value2: 500 },
        { label: "500亩-1000亩", value: 500, value2: 1000 },
        { label: "1000亩以上", value: 1000, value2: "" },
      ],
      typeOptions: [],
      statusOptions: [],
      changeTypeOptions: [
        { label: "全部", value: "全部" },
        { label: "在线自由竞价", value: "在线自由竞价" },
        { label: "现场交易", value: "现场交易" },
        { label: "协商", value: "协商" },
      ],
      shTypes: [
        {
          label: "未上传保证金",
          value: "0",
        },
        {
          label: "保证金待审核",
          value: "1",
        },
        {
          label: "已中标/已成交",
          value: "2",
        },
        {
          label: "保证金审核通过",
          value: "3",
        },
        {
          label: "保证金审核不通过",
          value: "4",
        },
        {
          label: "未中标",
          value: "5",
        },
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false, //导出合同组件显示判断条件
      payInfo: {},
      status: 0,
      msg: "",
      fileList: [],
      pzForm: {
        bzjFileUrl: "",
        xmbh: "",
      },
      visible: false,
      pzTitle: "",
      examineVisible: false,
      examineInfo: null,
      picLoading: false,
      noneBtnImg: false,
      UpLook: false,

      dialogVisiblejj: false,
      dialogVisible9: false,
      current: {},
      contractInfo: null,
      contractArray: [],
      selectRow: {},
    };
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  created() {
    this.initData();
    this.getRegions();
    this.getTypes();
    if (this.$route.query.c) {
      console.log(this.$route.query.c);
      this.getResult({ bzjId: this.$route.query.c });
    }
  },
  methods: {
    // 关闭弹框
    async closeDialog5() {
      this.UpLook = false;
      this.initData();
    },
    async initData() {
      // 获取数据
      this.tableData = [];
      let params = {
        // bmzt: this.form.status,
        xzqbh: this.form.region,
        cqlx: this.form.type,
        // jyfs: this.form.changeType,
        // zxmj: this.form.area || 0,
        // zdmj: this.form.maxArea,
        // xmbh: this.form.code,
        xmmc: this.form.name,
        pageNo: this.currentPage,
        pageSize: this.pageSize,
      };
      let res = await selectCollectionTrade(params);
      let { code, data, message } = res;
      if (data) {
        this.tableData = data.records;
        console.log("tableData", this.tableData);
        this.total = parseInt(data.total);
      }
    },
    // 条件查询
    query() {
      this.currentPage = 1;
      this.initData();
    },
    // 清空查询条件
    clear() {
      this.currentPage = 1;
      this.form = {
        area: "",
        region: "",
        type: "",
        changeType: "全部",
        code: "",
        name: "",
        maxArea: "",
      };
      this.initData();
    },
    async getRegions() {
      // 获取行政区树
      let param = {
        level: "3",
        parentCode: null,
      };
      let res = await getAreaTree(param);
      let { code, data, msg } = res;
      if (code == 0) {
        this.regionOptions = removeEmptyChildren(data);
      } else {
        this.$message.error(msg);
      }
    }, 
    // 取消收藏
    async noSelect(obj) {
      console.log(obj);
      this.$confirm("确定取消收藏?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消", 
        type: "warning", 
      })
        .then(async () => {
          let res = await  cancelCollectionXMXX({ xmbh: obj.xmbh });
          if (res.code == 0) {
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
          this.initData();
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 切换页码
    currentChange(val) {
      this.currentPage = val;
      this.initData();
    },
    handelSelectChange(val) {
      // 选择发生变化
      this.initData();
    },
    handleAreaChange(val) {
      // 面积发生变化
      let obj = this.areaOptions.filter((item) => item.value === val)[0];
      this.form.maxArea = obj.value2;
      this.initData();
    },
    handleBlur(val) {
      // 输入框失去焦点时发生变化
      this.initData();
    },
    handleRowClass({ row, rowIndex }) {
      // 行樣式
      if (rowIndex % 2 === 0) {
        return "odd";
      }
    },
    async getStatus() {
      // 判断认证状态
      let res = await getCertificateStatus(this.userInfo.username);
      let { status, data, msg } = res;
      if (status) {
        this.status = data;
        this.msg = msg;
      }
    },
    // 获取产权类型
    getTypes() {
      let cqList = JSON.parse(localStorage.getItem("types"));
      if (cqList && cqList.length > 0) {
        this.typeOptions = cqList.map((x) => {
          return {
            label: x.lx,
            value: x.bh,
          };
        });
      }
    },
    showDetail(row) {
      // 查看项目详情
      this.$router.push({ name: "projectDetail", query: { c: row.xmbh } });
    },
    async pay(row) {
      // 立即缴费
      this.dialogVisible = true;
      this.examineVisible = false;
      let res = await showAccount({ xmbh: row.xmbh });
      let { status, data } = res;
      if (status == 200) {
        this.payInfo = data;
      }
    },
    //上传凭证
    uploadPz(row) {
      this.examineVisible = false;
      this.visible = true;
      this.pzTitle = row.xmmc + "项目凭证上传";
      this.pzForm.xmbh = row.xmbh;
    },
    beforeUpload(file) {
      // 文件上传之前的函数
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 6;
      if (!isJPG) {
        this.$message.error("上传图片为 JPG 或 PNG 格式!");
        this.isUpload = false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 6MB!");
        this.isUpload = false;
      }
      return isJPG && isLt2M;
    },
    upload(file, fileList) {
      if (fileList.length > 0) {
        this.noneBtnImg = true;
      }
      this.uploadFile(file);
    },
    // 删除已上传的凭证
    remove(file, fileList) {
      this.fileList = fileList;
      this.picLoading = false;
      this.pzForm.bzjFileUrl = null;
      this.noneBtnImg = false;
    },
    // 执行文件上传
    async uploadFile(file) {
      console.log(111111);
      this.picLoading = true;
      let param = new FormData();
      param.append("catalog", "vip");
      param.append("file", file.raw);
      let res = await uploadFile(param);
      this.picLoading = false;
      if (res.code == 0) {
        this.pzForm.bzjFileUrl = res.data.url;
        this.$message.success(res.msg ? res.msg : "上传成功");
      } else {
        this.$message.error(res.msg ? res.msg : "上传失败");
      }
    },
    // 提交保证金凭证信息
    async savePzxx() {
      if (!this.pzForm.bzjFileUrl) {
        this.$message.warning("请上传保证金缴费凭证！");
        return;
      }
      let params = Object.assign({}, this.pzForm);
      let res = await savePzxx(params);
      let { status, message } = res;
      if (status == 200) {
        this.$message.success(message);
        this.visible = false;
        this.initData();
      } else {
        this.$message.error(message);
      }
    },
    dialogClose(val) {
      // 关闭弹窗
      this.dialogVisible = val;
      this.initData();
    },
    //获取审核结果
    async getResult(val) {
      console.log("val", val);
      let res = await getBzjCheckResult({ bzjId: val.twTradeBmbzj.id });
      let { status, data, message } = res;
      if (status == 200) {
        this.examineInfo = data;
        this.examineInfo.bzjPz =
          window.globalUrl.HOME_API +
          "admin/file/get?ossFilePath=" +
          this.examineInfo.bzjPz;
        this.examineVisible = true;
      } else {
        this.$message.error(message);
      }
    },
    // 关闭审核结果查看
    examineClose() {
      this.examineVisible = false;
    },
    // 打开审核结果弹框
    openPzxx(val) {
      this.examineVisible = false;
      this.uploadPz(val);
    },
    //查看
    upLook(obj, isUp) {
      console.log("obj,isUp", obj, isUp);
      this.UpLook = true;
      this.$nextTick(() => {
        this.$refs.UpLook.Init(obj, isUp);
      });
    },
    // 点击竞价
    async bidding(row) {
      console.log(row, "这些事row的数据");
      let params = {
        xmbh: row.xmbh,
        authId: this.identityObj.identityId ? this.identityObj.identityId : "",
        currentIdentity: this.identityObj.identityTypeCode
          ? this.identityObj.identityTypeCode
          : "",
        userId: this.identityObj.userId ? this.identityObj.userId : "",
      };
      let buttonRes = await getButtonStatus(params);
      console.log("buttonRes", buttonRes);
      if (buttonRes.status == 200 && buttonRes.data.type == "8") {
        this.$message.error(buttonRes.data.detail);
        return;
      }
      // 竞价
      let res = await getXmxxDetail({ xmbh: row.xmbh });
      console.log("竞价", res);
      let { status, data } = res;
      if (status == 200) {
        this.current = data;
      }
      this.dialogVisiblejj = true;
    },

    async submit(val, offer) {
      // 竞价信息提交 offer当前报价
      let total = 0;
      if (offer == 0 || !offer) {
        total = parseFloat(val) + parseFloat(this.current.gpjg);
      } else {
        total = parseFloat(val) + parseFloat(this.current.dqbj);
      }
      let params = {
        xmbh: this.current.xmbh,
        amount: total,
      };
      let res = await saveBidding(params);
      console.log("竞价信息提交", res);
      let { status, data, message } = res;
      if (status == 200) {
        this.$message.success(message);
        this.dialogVisiblejj = false;
        this.initData();
      } else {
        this.$message.error(message);
      }
    },
    // 关闭弹窗
    dialogClosejj(val) {
      this.dialogVisiblejj = val;
      this.initData();
    },

    //导出合同
    async exportHt(row) {
      this.selectRow = row;
      console.log(row, "查看能否查看到类型");
      //cqlxbh产权类型编号
      if (row.currentIdentity == "3" && row.cqlxbh == "01") {
        let resCheck = await checkContract({ type: "0", xmbh: row.xmbh });
        let resCheck2 = await checkContract({ type: "1", xmbh: row.xmbh });
        console.log("4655646", resCheck, resCheck2);
        if (resCheck.rel && resCheck2.rel) {
          let res1 = await viewContract({ type: "0", xmbh: row.xmbh });
          let res2 = await viewContract({ type: "1", xmbh: row.xmbh });
          console.log("res1,res2", res1, res2);
          this.contractArray = [res1, res2];
          this.dialogVisible9 = true;
        } else if (!resCheck.rel) {
          this.$message.error(resCheck.message);
          return;
        }
      } else {
        let resCheck = await checkContract({ type: "0", xmbh: row.xmbh });
        console.log("resCheck", resCheck);
        if (!resCheck.rel) {
          this.$message.error(resCheck.message);
          return;
        }
        let res = await await viewContract({ type: "0", xmbh: row.xmbh });
        console.log("res111", res);
        if (res) {
          this.contractInfo = res;
          this.dialogVisible9 = true;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ybmxm {
  text-align: left;
  height: 100%;
  position: relative;
  // margin-top: 46px;

  .s-title {
    .title {
      margin-left: 10px;
      font-size: 15px;
      font-weight: 600;
    }
  }
  .project {
    // margin-top: 38px;
    .table-con {
      margin-top: 12px;
      padding: 0 15px 0 18px;
    }
  }
  .news {
    margin-top: 33px;
  }
  .pagenation {
    // margin-top: 0;
    // position: absolute;
    // right: 20px;
    // bottom: 33px;
    text-align: right;
  }
}
.query-form {
  display: flex;
  .el-form-item {
    display: flex;
    align-items: center;
    .el-form-item__content {
      width: 100px;
    }
  }
  .query-btn {
    width: 70px;
  }
}
</style>

<style lang="less">
.ybmxm {
  .el-dialog__wrapper {
    position: absolute;
  }
  .v-modal {
    position: absolute;
  }
  .el-checkbox-button__inner {
    width: 160px;
    border-left: 1px solid #dcdfe6;
  }
  .el-dialog--center .el-dialog__body {
    padding: 25px 60px 30px 38px;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    border-radius: 5px;
    border: solid 1px #00a0e9;
    background: #fff;
    color: #00a0e9;
  }
  .el-dialog__footer {
    padding: 10px 20px 45px;
  }
}
.disUoloadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
</style>
